import React from 'react';
import { graphql } from 'gatsby';
import PageInternalTemplate from '../templates/page-template';

const ChiSiamoTemplate = ({ data, pageContext }) => {
  return (
    <PageInternalTemplate data={data} pageContext={pageContext} />
  );
};

export default ChiSiamoTemplate;

export const PAGE_QUERY = graphql`
  query (
      $slug: String!
      $isCategory: Boolean = true,
      $isTopic: Boolean = false,
      $relatedNewsFilter: String!
  ) {
    datoCmsPage: datoCmsPagesChisiamo(slug: {eq: $slug}) {
      id
      root
      slug
      title
      seoMetaTags {
        tags
      }
      treeParent {
        id
        slug
        title
        treeChildren {
          id
          slug
          title
        }
      }
      subtitle
      content
      contentAccordion
      newsFeedModularBlock {
        ...NewsFeedModularBlock
        ... on DatoCmsNewsByRelationship {
          title
          newsNumber
          model {
            apiKey
          }
        }
      }
      model {
        apiKey
        name
      }
      coverImage {
        alt
        fluid(
          imgixParams: {fm: "jpg", auto: "compress", dpr: 3}
          sizes: "(max-width: 1920px) 100vw, 100vw"
        ) {
          ...GatsbyDatoCmsFluid
        }
      }
      coverImageMobile {
        alt
        fluid(
          imgixParams: {fm: "jpg", auto: "compress", dpr: 3},
          sizes: "(max-width: 767px) 100vw, 100vw"
        ) {
          ...GatsbyDatoCmsFluid
        }
      }
      widgetCard {
        id
        title
        description
        colorTheme
        email
        phone
        iconImageModularContent {
          ... on DatoCmsIcon {
            id
            iconType
          }
          ... on DatoCmsImage {
            id
            imageType {
              alt
              url
            }
          }
        }
        buttonModularContent {
          labelButton {
            value
          }
          url
        }
      }
      visualComposer {
        ...AccordionWithIntro
        ...BannerBigImage
        ...BannerDouble
        ...BannerFullImage
        ...BannerIcon
        ...BannerText
        ...BannerTextWithModal
        ...CenteredNumbers
        ...DataVisualization
        ...Download
        ...FullImage
        ...LogosList
        ...MediumImage
        ...Numbers
        ...Quote
        ...Paragraph
        ...PeopleDetail
        ...PeopleList
        ...Spacing
        ...SliderGeneral
        ...SliderImagesGallery
        ...SlidePartner
        ...SliderShop
        ...SliderStory
        ...SliderTestimonial
        ...VideoEmbed
        ...Form
      }
    }
    datoCmsNewsArchive {
      slug
    }
    datoCmsCookieIubenda {
      showCookieIubenda
    }
    allDatoCmsNews(limit: 10, sort: {fields: publicationDate, order: DESC}) {
      nodes {
        id
        paragraph
        title
        slug
        publicationDate
        coverImage {
          alt
          fluid(
            imgixParams: {fm: "jpg", auto: "compress", dpr: 3},
            sizes: "(max-width: 1920px) 300px"
          ) {
            ...GatsbyDatoCmsFluid
          }
        }
        format {
          name
          slug
        }
        topic {
          slug
          name
          categoryNews {
            name
            slug
          }
        }
      }
    }
    newsByCategory: allDatoCmsNews(
        limit: 9,
        sort: {
          fields: publicationDate, order: DESC
        }
        filter: {
          topic:
          {
            categoryNews: {
              slug: {
                eq: $relatedNewsFilter
              }
            }
          }
        }
    ) @include(if: $isCategory) {
      nodes {
        title
        slug
        topic {
          id
          name
          slug
          categoryNews {
            id
            name
            slug
          }
        }
        publicationDate
        model {
          apiKey
        }
        coverImage {
          alt
          fluid(
            imgixParams: {fm: "jpg", auto: "compress", dpr: 3},
            sizes: "(max-width: 1920px) 300px"
          ) {
            ...GatsbyDatoCmsFluid
          }
        }
      }
    }
    newsByTopic: allDatoCmsNews(
      limit: 9,
      sort: {
          fields: publicationDate, order: DESC
      }
      filter: {
        topic: {
          slug: {
            eq: $relatedNewsFilter
          }
        }
      }
    ) @include(if: $isTopic) {
      nodes {
        title
        slug
        topic {
          id
          name
          slug
          categoryNews {
            id
            name
            slug
          }
        }
        publicationDate
        model {
          apiKey
        }
        coverImage {
          alt
          fluid(
            imgixParams: {fm: "jpg", auto: "compress", dpr: 3},
            sizes: "(max-width: 1920px) 300px"
          ) {
            ...GatsbyDatoCmsFluid
          }
        }
      }
    }
  }
`;
